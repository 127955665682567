import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { BasicLayout } from "../../layouts/BasicLayout";
import { mobileSize } from "../../util/variables";
import { AppLogo, AppTitle, AppText } from "../../components/ui";
import StepIndicator from "../../components/gift/StepIndicator";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { AppLink } from "../../components/ui/AppLink";
import theme from "../../util/theme";
import { gift_page_view } from "../../services/mixpanel/gift-flow-events";
import { fbq } from "../../services/pixel";
import { LocalPriceContext } from "../../providers/LocalPriceProvider";

const Start = () => {
  const { currentPrice: localPrice } = useContext(LocalPriceContext);
  useEffect(() => {
    gift_page_view("GiftPurchaseComplete");
    fbq("track", "Purchase", {
      currency: localPrice?.currency.code,
      value: 99.99,
    });
  }, []);

  const imageData = useStaticQuery(graphql`
    query giftPurchased {
      gift: file(relativePath: { eq: "gift/gift-open.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <BasicLayout>
      <Container>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <LogoContainerMobile>
          <AppLogo size="x-small" />
        </LogoContainerMobile>
        <StepContainer>
          <StepIndicator numSteps={3} currentStep={3} />
        </StepContainer>
        <DesktopImgContainer>
          <Img fluid={imageData.gift.childImageSharp.fluid} />
        </DesktopImgContainer>
        <TextContainer>
          <H1 style={{ color: "#10130C" }}>Gift Purchase Complete!</H1>
          <AppText
            fontSize={24}
            mobileFontSize={20}
            fontWeight={400}
            textAlign="center"
            style={{ maxWidth: "32ch", margin: "0 auto 16px auto", color: "#10130C" }}
          >
            A receipt will be sent to your email shortly.
          </AppText>
          <AppText
            fontSize={24}
            mobileFontSize={20}
            fontWeight={400}
            textAlign="center"
            style={{ maxWidth: "32ch", margin: "0 auto 16px auto", color: "#10130C" }}
          >
            Your recipient will be notified via email. They can start reaching their learning goals
            with Imprint immediately.
          </AppText>
        </TextContainer>
        <MobileImgContainer>
          <Img fluid={imageData.gift.childImageSharp.fluid} />
        </MobileImgContainer>
        <StyledAppLink to="/gift/start">Send Another Gift</StyledAppLink>
        <QuestionContainer style={{ color: theme.BLACK_COLOR }} aria-label="Contact information">
          Questions? Email us at <a href="mailto:info@imprintapp.com">info@imprintapp.com</a>
        </QuestionContainer>
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 80px 64px;
  height: 100vh;
  @media ${mobileSize} {
    padding: 40px 24px;
    min-height: 100svh;
    height: auto;
  }
`;

const StepContainer = styled.div`
  @media ${mobileSize} {
    margin: 28px 0 32px 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    display: none;
  }
`;

const LogoContainerMobile = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: static;
    margin-top: 0px;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 8px;
  @media ${mobileSize} {
    margin: 0;
  }
`;

const H1 = styled(AppTitle as any)`
  text-align: center;
  margin-bottom: 16px;
  margin-top: 32px;
  @media ${mobileSize} {
    margin-top: 0;
  }
`;

const DesktopImgContainer = styled.div`
  max-width: 365px;
  width: 100%;
  margin-top: 80px;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileImgContainer = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    width: 100%;
    max-width: 264px;
    margin-top: 35px;
  }
`;

const StyledAppLink = styled(AppLink)`
  margin: auto;
  @media ${mobileSize} {
    margin: 30px 0 40px 0px;
  }
`;

const QuestionContainer = styled.div`
  margin-top: 8px;
  @media ${mobileSize} {
    margin-top: 0px;
  }
`;
export default Start;
